var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hv-checkout-info collapse-info-box" }, [
    _c("i", {
      class: ["big-icon", _vm.textIcon],
      attrs: { "aria-hidden": "true" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "title-info" }, [_vm._v(_vm._s(_vm.textTitle))]),
    _vm._v("\n    " + _vm._s(_vm.textHeader) + "\n    "),
    !_vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "toggle-btn",
            on: {
              click: function ($event) {
                _vm.isOpen = !_vm.isOpen
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$translate(
                    "CeresCoconut::Template.checkoutInfoBoxSeeMore"
                  )
                ) +
                " "
            ),
            _c("i", {
              staticClass: "fa fa-chevron-down",
              attrs: { "aria-hidden": "true" },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isOpen
      ? _c("div", [_vm._v("\n        " + _vm._s(_vm.textBody) + "\n    ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "toggle-btn",
            on: {
              click: function ($event) {
                _vm.isOpen = !_vm.isOpen
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$translate(
                    "CeresCoconut::Template.checkoutInfoBoxSeeLess"
                  )
                ) +
                " "
            ),
            _c("i", {
              staticClass: "fa fa-chevron-up",
              attrs: { "aria-hidden": "true" },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }