<template>
  <div class="hv-checkout-info collapse-info-box">
      <i :class="['big-icon',textIcon]" aria-hidden="true"></i>
      <div class="title-info">{{ textTitle }}</div>
      {{ textHeader }}
      <div class="toggle-btn" v-if="!isOpen" @click="isOpen = !isOpen" >
        {{ $translate("CeresCoconut::Template.checkoutInfoBoxSeeMore") }} <i class="fa fa-chevron-down" aria-hidden="true"></i>
      </div>
      <div v-if="isOpen">
          {{ textBody }}
      </div>
      <div class="toggle-btn" v-if="isOpen" @click="isOpen = !isOpen" >
        {{ $translate("CeresCoconut::Template.checkoutInfoBoxSeeLess") }} <i class="fa fa-chevron-up" aria-hidden="true"></i>
      </div>
  </div>
</template>

<script>
export default {

    props:
    {
        textTitle:
        {
            type: String,
            default: ""
        },
        textHeader:
        {
            type: String,
            default: ""
        },
        textBody:
        {
            type: String,
            default: ""
        },
        textIcon:
        {
            type: String,
            default: ""
        }
    },
    data() {
      return {
        isOpen: false
      }
    }
}
</script>
